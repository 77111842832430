import React from 'react'
import { Link } from 'gatsby'


const Header = () => (
    <section className='header'>
      <Link to="/" style={{ textShadow: `none`, backgroundImage: `none` }}>
        <header style={{ display: `inline` }}>Oxen<sub>[ˈhɛksən]</sub> Project</header>
        <p className="subheader">world-space in pure continuous interchange with my own being</p>
      </Link>
    </section>
)

export default Header
