import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

const Nav = ({ children }) => {
  const data = useStaticQuery(graphql`
  query {
    allMdx(
      sort: {fields: frontmatter___datePublished}
      filter: { slug: { regex: "/^((?!_hidden_).)*$/"} }
      ) {
      nodes {
        id
        frontmatter {
          title
          datePublished(formatString: "MMMM D, YYYY")
          type
          medium
          year
        }
        slug
      }
    }
  }
`)

console.log(data);

return (
  <section className="nav">
    <ul className="project">
      <header className="project-header">projects</header>
    {
      data.allMdx.nodes.filter((node) => (
        node.frontmatter.type === "project"
      )).map((node) => (
        <article key={node.id}>
          <li>
            <Link to={`/posts/${node.slug}`}>
              {/* <span className="medium">{node.frontmatter.medium}</span> */}{node.frontmatter.title} [{node.frontmatter.year}]
            </Link>
          </li>
        </article>
      ))
    }
    </ul>
    <ul className="research">
      <header className="research-header">research | writing</header>
    {
      data.allMdx.nodes.filter((node) => (
        node.frontmatter.type === "writing"
      )).map((node) => (
        <article key={node.id}>
          <li>
            <Link to={`/posts/${node.slug}`}>
              {node.frontmatter.title}
            </Link>
          </li>
        </article>
      ))
    }
    </ul>
  </section>
)
}

export default Nav
