import React from 'react'

import ArenaChannels from './arenaChannels'
import Bio from './bio'
import Header from './header'
import Nav from './nav'
import './layout.css'


const Layout = ({ children }) => {
  return (
    // <div style={{ margin: `3rem auto`, maxWidth: 850, padding: `0 1rem` }}>
    //   <Header></Header>
    //   <h2>{pageTitle}</h2>
    //   <main className='main'>
    //     {children}
    //   </main>
    // </div>
    <main id="main-layout">
      <section class="column side left">
        <Nav></Nav>
      </section>
      <section class="column center">
        <Header></Header>
        {children}
      </section>
      <section class="column side right">
        <Bio></Bio>
        <ArenaChannels></ArenaChannels>
      </section>
    </main>
  )

}

export default Layout


