import React from 'react'
import { Link } from 'gatsby'

const Bio = ({ children }) => {
  return (
    <section className='bio'>
       <ul>
          <header>contact</header>
          <li>
            <a href='mailto:arcadychang@gmail.com'>mailto@arcadychang@gmail.com</a>
          </li>
          <li>
            <Link to="https://www.instagram.com/shedoesntknowwhatshesdoing/">instagram</Link>
          </li>
          <li>
          <Link to="https://www.are.na/kat-zhang">are.na</Link>
          </li>
        </ul>
    </section>
  )
}

export default Bio
